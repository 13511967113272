//FONT
@import "~ui-kit.aspentech.com/dist/themes/default/abstracts/mixins";
@import "~ui-kit.aspentech.com/dist/themes/default/abstracts/variables";

.app{
  
    .nav_header{
        @media (max-width:41em){
            .icon-box{
                .desktop-logo{
                    display: none;
                }
            }
           
        }   

        @media (min-width: 41em) {
            .icon-box{
                .mobile-logo{
                    display: none;
                }
            }
           
        }   
        
        background: $blue-dark;
        padding-top: 1em;
        padding-bottom: 1.25em;
        width: 100%;

        .icon-box{
            //border: 1px solid red;
            height: 3em;
            margin-left: 1em;

            svg {
                height: 3em;

                &.desktop-logo{
                    width: 28em;
                }

                &.mobile-logo{
                    width: 12em;
                }
            }
        }
    }


    
    @media (max-width: $bp-large) {
        .footer__basic {
            .privacy {
                margin: 2em 0;
            }
            flex-direction: column;
            align-items: center;
        }
    }

    .footer__basic {
        background: $color-black;
        padding: 2em;
        color: $color-white;
        display: flex;
        justify-content: space-between;
        
        .logo-footer{
            width: 14em;

            svg{
                width: 100%;
            }
        }

        .privacy {
            align-self: center;
            a {
                margin: 0 1em;
            }
        }

        .socialMedia {
            display:inline-flex;
        }
    }

}